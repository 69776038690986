import { NavLink } from 'react-router-dom';
import routes from '../../data/routes';
import './Header.css';

const Header = () => {
  return (
    <header className="header">
      <NavLink end to={routes.HOME}>
        <div className="header__logo" />
      </NavLink>

      <nav className="header__nav">
        <ul className="header__nav__list">
          <li className="header__nav__item">
            <NavLink end to={`${routes.HOME}?page=1`}>Home</NavLink>
          </li>
          <li className="header__nav__item">
            <NavLink end to={routes.VIRTUAL_SCROLLING}>Virtual Scrolling</NavLink></li>
        </ul>
      </nav>
    </header >
  );
};

export default Header;
