import { useAppContext } from '../../appContext';
import './Footer.css';

const PrevNextButton = ({ prevNext, page, setPage, totalPages }) => {
  const onClick = prevNext === 'prev' ? () => setPage(page - 1) : () => setPage(page + 1);
  const disabled = prevNext === 'prev' ? page === 1 : page === totalPages;
  const text = prevNext === 'prev' ? '⬅️' : '➡️';

  return (
    <button className="footer__prev-next" onClick={onClick} disabled={disabled}>
      {text}
    </button>
  );
};

const Footer = () => {
  const { page, setPage, count, totalPages } = useAppContext();

  return (
    <footer className="footer">
      <PrevNextButton prevNext="next" page={page} setPage={setPage} totalPages={totalPages} />
      <PrevNextButton prevNext="prev" page={page} setPage={setPage} totalPages={totalPages} />
      <span className="footer__count-indicator">Items: {count}</span>
      <span className="footer__page-indicator">Page: {page}</span>
    </footer>
  );
};

export default Footer;
