import { useMemo } from 'react';
import { columnsValues as columns } from '../../data/consts';
import { useAppContext } from '../../appContext';
import './GridTable.css';

function GridTable({ defaultColumn = 'id' }) {
  const { items, desc, setDesc, sortBy, setSortBy } = useAppContext();

  const sortedItems = useMemo(() => {
    if (sortBy === null) {
      return items;
    }

    return items.slice().sort((a, b) => {
      if (a[sortBy] === b[sortBy]) {
        return 0;
      }

      if (desc) {
        return a[sortBy] < b[sortBy] ? 1 : -1;
      } else {
        return a[sortBy] < b[sortBy] ? -1 : 1;
      }
    });
  }, [sortBy, desc, items]);

  const handleSort = (column) => {
    if (column === sortBy) {
      if (desc) {
        // on third click, reset sort to default column
        setSortBy(defaultColumn);
        setDesc(false);
      } else {
        // on second click, sort descending
        setDesc(true);
      }
    } else {
      // on first click, sort ascending
      setSortBy(column);
      setDesc(false);
    }
  };

  return (
    <div className="App">
      <div className="table">
        <div className="table__row table__row--header">
          {columns.map((column) => (
            <div key={column.key} className="table__row__cell" onClick={() => handleSort(column.key)}>
              {column.name}
              <SortIcon key={column.key} sortBy={sortBy} columnKey={column.key} desc={desc} />
            </div>
          ))}
        </div>
        <div className="table__body">
          {sortedItems.map((item) => (
            <div key={item.id} className="table__row">
              <div className="table__row__cell">{item.first_name}</div>
              <div className="table__row__cell">{item.last_name}</div>
              <div className="table__row__cell">{item.occupation}</div>
              <div className="table__row__cell">{item.email}</div>
              <div className="table__row__cell">{item.ip_address}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function SortIcon({ sortBy, columnKey, desc }) {
  if (sortBy === columnKey) {
    return desc ? <span> &#x2191;</span> : <span> &#x2193;</span>;
  }
  return '';
}

export default GridTable;
