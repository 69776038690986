import { MOCK_DATA } from '../data/MOCK_DATA';

class ApiClient {
  getData = async ({ page = 1, itemsPerPage = 100 }) => {

    return new Promise((resolve) => {
      setTimeout(() => {
        // get max page number
        const totalPages = Math.ceil(MOCK_DATA.length / itemsPerPage);

        // if page is out of range, return empty data
        if (page < 1 || page > totalPages) {
          resolve({
            count: 0,
            items: []
          });
        }

        // depending on page number,
        const items = MOCK_DATA.slice((page - 1) * itemsPerPage, page * itemsPerPage);

        resolve({
          count: (page - 1) * itemsPerPage + items.length,
          totalPages,
          items
        });
      }, 300);
    });
  };
}

export default new ApiClient();
