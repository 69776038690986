import { useAppContext } from '../../appContext';
import './Loading.css';
import loadingImg from '../../loading.gif';

const Loading = () => {
  const { loading } = useAppContext();

  if (!loading) return null;

  return (
    <div className="loading">
      <img className="loading__img" src={loadingImg} alt="loading..." />
    </div>
  );
};

export default Loading;
