import { useState, useMemo, useEffect } from 'react';
import debounce from 'lodash.debounce';

import './Search.css';
import { useAppContext } from '../../appContext';

const Search = () => {
  const { search, setSearch } = useAppContext();
  const [searchTerm, setSearchTerm] = useState(search);

  // debounce the search term so that it only updates the context
  // after the user has stopped typing for 500ms
  const debouncedSearchUpdate = useMemo(
    () => debounce(setSearch, 500)
    , [setSearch]);

  // Stop the invocation of the debounced function
  // after unmounting
  useEffect(() => {
    return () => {
      debouncedSearchUpdate.cancel();
    }
  }, [debouncedSearchUpdate]);

  const handleSearch = (e) => {
    const { value } = e.target;

    setSearchTerm(value);
    debouncedSearchUpdate(value);
  };

  return (
    <div className="search">
      <input
        className="search__input"
        placeholder="Search by e-mail..."
        onChange={handleSearch}
        value={searchTerm}
        autoFocus
      />
    </div>
  );
};

export default Search;
