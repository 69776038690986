import React from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider, createRoutesFromElements, Route } from 'react-router-dom';

import './index.css';
import App from './pages/App';
import VirtualScrolling from './pages/VirtualScrolling';

import { AppProvider } from './appContext';
import routes from './data/routes';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path={routes.HOME}>
      <Route path={routes.HOME} element={<App />} />
      <Route path={routes.VIRTUAL_SCROLLING} element={<VirtualScrolling />} exact />
    </Route>
  )
);

createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <AppProvider>
      <RouterProvider router={router} />
    </AppProvider>
  </React.StrictMode>
);
