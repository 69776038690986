import { useEffect } from "react";
import useLoadNewPageOnScroll from "../../hooks/useLoadNewPageOnScroll";
import { useAppContext } from '../../appContext';
import { defaults } from "../../data/consts";

import Header from "../../components/Header";
import Loading from "../../components/Loading";
import Search from "../../components/Search";
import GridTable from "../../components/GridTable";
import Footer from "../../components/Footer";

const App = () => {
  const { setItemsPerPage, loadSearchParams } = useAppContext();

  useEffect(() => {
    // set items per page to the default value
    setItemsPerPage(defaults.homePage.itemsPerPage);
    // load initial state from search params
    loadSearchParams();
  }, [loadSearchParams, setItemsPerPage]);

  // if user scrolls to the bottom of the page then
  // load the data for that page
  useLoadNewPageOnScroll();

  return (
    <>
      <Header />
      <main>
        <Loading />
        <Search />
        <GridTable />
      </main>
      <Footer />
    </>
  );
};

export default App;
