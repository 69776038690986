export const columnsValues = [
  { name: 'First Name', key: 'first_name' },
  { name: 'Last Name', key: 'last_name' },
  { name: 'Occupation', key: 'occupation' },
  { name: 'E-Mail', key: 'email' },
  { name: 'IP Address', key: 'ip_address' }
];

export const defaults = {
  homePage: {
    itemsPerPage: 100,
  },
  virtualScrollingPage: {
    itemsPerPage: 1000,
  }
}
