import { columnsValues as columns } from '../../data/consts';
import './VirtualScrollingTable.css';

import { useInView } from "react-intersection-observer";

/**
 * This component computes inline style and
 * handles whether to display props.children.
 */
function VirtualScrollElement({ height, children }) {
  const [ref, inView] = useInView();
  const style = {
    height: `${height}px`,
    overflow: 'hidden'
  };
  return (
    <div style={style} className="vstable__row" ref={ref}>
      {inView ? children : null}
    </div>
  );
}

function GridTable({ items }) {
  return (
    <div className="App">
      <div className="vstable">
        <div className="vstable__row vstable__row--header">
          {columns.map((column) => (
            <div key={column.key} className="vstable__row__cell">
              {column.name}
            </div>
          ))}
        </div>
        <div className="vstable__body">
          {items.map((item) => (
            <VirtualScrollElement key={item.id} height={33}>
              <div className="vstable__row__cell">{item.first_name}</div>
              <div className="vstable__row__cell">{item.last_name}</div>
              <div className="vstable__row__cell">{item.occupation}</div>
              <div className="vstable__row__cell">{item.email}</div>
              <div className="vstable__row__cell">{item.ip_address}</div>
            </VirtualScrollElement>
          ))}
        </div>
      </div>
    </div>
  );
}

export default GridTable;
