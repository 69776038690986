import { useEffect } from 'react';
import { useAppContext } from '../appContext';

export default function useLoadNewPageOnScroll() {
  const { totalPages, page, setPage } = useAppContext();

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, offsetHeight } = document.documentElement;

      // if the user hasn't scrolled to the bottom of the page, do nothing
      if (window.innerHeight + scrollTop !== offsetHeight) {
        return;
      }

      // if we are on the last page, do nothing
      if (page >= totalPages && totalPages !== null) {
        return;
      }

      // when user scrolls to the bottom of the page, load next page
      setPage((prevPage) => prevPage + 1);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [page, totalPages, setPage]);
}
