import React, { createContext, useState, useEffect, useContext, useRef } from 'react';
import ApiClient from './api';
export const AppContext = createContext();

const loadInitialStateFromSearchParams = () => {
  const searchParams = new URLSearchParams(window.location.search);

  return {
    page: parseInt(searchParams.get('page'), 10) || 1,
    search: searchParams.get('search') || '',
    sortBy: searchParams.get('sortBy') || 'id',
    desc: searchParams.get('desc') === 'true' || false
  };
};

const updateUrlParam = (key, value) => {
  const { search, pathname } = window.location;
  const searchParams = new URLSearchParams(search);

  searchParams.set(key, value);
  window.history.pushState({}, '', `${pathname}?${searchParams}`);
};

export const AppProvider = ({ children }) => {
  const isMounted = useRef(false);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [search, setSearch] = useState('');
  const [desc, setDesc] = useState(false);
  const [sortBy, setSortBy] = useState('id');
  const [loading, setLoading] = useState(false);
  const [{ count, totalPages, items }, setData] = useState({
    count: 0,
    totalPages: null,
    items: []
  });

  // on load set initial state from search params
  const loadSearchParams = () => {
    if (isMounted.current) return;
    isMounted.current = true;

    const params = loadInitialStateFromSearchParams();
    setPage(params.page);
    setSearch(params.search);
    setDesc(params.desc);
    setSortBy(params.sortBy);
  }


  // handle loading data from API, based on page number
  //  use memoized value to avoid unnecessary re-renders of loadPage
  const loadPage = React.useCallback(async function (page, itemsPerPage = 100) {
    if (page > totalPages && totalPages !== null) {
      return;
    }

    setLoading(true);
    const response = await ApiClient.getData({ page, itemsPerPage });
    setLoading(false);

    setData(response);

    window.scrollTo({ top: 0, left: 0 });
  }, [totalPages]);


  // when state changes, update URL params
  useEffect(() => {
    updateUrlParam('search', search);
  }, [search]);
  useEffect(() => {
    updateUrlParam('sortBy', sortBy);
  }, [sortBy]);
  useEffect(() => {
    updateUrlParam('desc', desc);
  }, [desc]);
  useEffect(() => {
    updateUrlParam('page', page);
    loadPage(page, itemsPerPage);
  }, [page, itemsPerPage, loadPage]);

  // handle searching: include only items which email address
  // matches the search string
  const filteredItems = items.filter((item) => item.email.includes(search));


  const value = {
    count,
    desc,
    items: filteredItems,
    itemsPerPage,
    loading,
    loadPage,
    loadSearchParams,
    page,
    search,
    setDesc,
    setItemsPerPage,
    setPage,
    setSearch,
    setSortBy,
    sortBy,
    totalPages,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within AppProvider');
  }
  return context;
};
