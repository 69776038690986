import { useEffect } from 'react';
import { useAppContext } from '../../appContext';

import Header from "../../components/Header";
import VirtualScrollingTable from "../../components/VirtualScrollingTable";
import Footer from "../../components/Footer";

import { defaults } from "../../data/consts";


const VirtualScrolling = () => {
  const { setItemsPerPage, setPage, items } = useAppContext();

  useEffect(() => {
    // set number of pages to the default value for the VirtualScrolling page
    setItemsPerPage(defaults.virtualScrollingPage.itemsPerPage);
    // reset page number to 1
    setPage(1);
  }, [setItemsPerPage, setPage]);

  return (
    <>
      <Header />
      <main>
        <VirtualScrollingTable items={items} />
      </main>
      <Footer />
    </>
  );
};

export default VirtualScrolling;
